import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';

import ExitWarning from './exitWarning';
import helpers from '../helpers';

const Root = styled('div')(({ theme }) => ({
    padding: 15,
    paddingBottom: 10,
    minWidth: 510,
    zIndex: 10,
    backgroundColor: theme.palette.background.lighter,
}));

const Logo = styled('img')({
    height: 25,
    marginRight: 7,
});

const StatsText = styled(Typography)(({ theme }) => ({
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.title,
}));

const TextTablet = styled(Typography)(({ theme, isDark }) => ({
    borderRadius: 3,
    fontWeight: 700,
    padding: '5px 8px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: isDark ? theme.palette.background.main : theme.palette.background.lighter,
    color: isDark ? '#fff' : theme.palette.text.main,
    border: `1px solid ${isDark ? theme.palette.background.main : theme.palette.background.light}`,
    fontSize: 11,
    height: 15,
    marginLeft: 5,
    marginBottom: 5,
}));

const PathogenTablet = styled(Typography)(({ theme }) => ({
    borderRadius: 3,
    fontWeight: 700,
    padding: '5px 8px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.title,
    fontSize: 11,
    height: 17,
    marginLeft: 5,
    marginBottom: 5,
    opacity: 0.5,
    fontWeight: 700,
}));

const TextTabletAddOn = styled('span')(({ theme }) => ({
    fontWeight: 500,
    whiteSpace: 'pre',
    backgroundColor: theme.palette.background.light,
    opacity: 0.2,
}));

const HeaderContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
});

const LogoContainer = styled('div')({
    width: 120,
});

const StatsContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: '135px 1fr',
    marginTop: 5,
});

const TextTabletWrapper = styled('div')({
    textAlign: 'left',
    minWidth: 700,
});

const HomeButton = styled(Button)(({ theme }) => ({
    width: 115,
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const Header = (props) => {
    const navigate = useNavigate();

    const { tiparsDisabled, pathogenName } = props;
    const staticMeta = useSelector(state => state.staticReducer.meta);
    const staticGlobalData = useSelector(state => state.staticReducer.staticGlobalData);
    const { filtersActive, filteredGlobalData, staticSubtreeData, filteredSubtreeData, staticNodeData, filteredNodeData } = useSelector(state => state.filtersReducer);

    const activeData = (filtersActive ? (filteredNodeData ?? filteredSubtreeData ?? filteredGlobalData) : null) ?? (staticNodeData ?? staticSubtreeData ?? staticGlobalData);

    const tiparsDisabledTextTablet = () => (
        <TextTablet isDark={true}>
            tipars: <TextTabletAddOn style={{ backgroundColor: tiparsDisabled ? '#b00000' : '#00801e', opacity: 1 }}> {tiparsDisabled ? 'DISABLED' : 'ENABLED'} </TextTabletAddOn>
        </TextTablet>
    );

    const renderTextTablet = (label, value, skeletonLen, isDark = false) => (
        <TextTablet isDark={isDark}>
            {label}: <TextTabletAddOn style={{ backgroundColor: (activeData && value) ? 'transparent' : null, opacity: (activeData && value) ? 1 : null }}>{(activeData && value) ?? ' '.repeat(skeletonLen)}</TextTabletAddOn>
        </TextTablet>
    );

    const [open, setOpen] = React.useState(false);

    const _onOpen = () => {
        setOpen(true);
    };

    const confirmExit = () => {
        setOpen(false);
        navigate('/tryNow');
    };

    const rejectExit = () => {
        setOpen(false);
    };
  
    return (
        <Root>
            <HeaderContainer>
                <HomeButton
                    disableRipple={true}
                    onClick={() => {
                        _onOpen();
                    }}
                >
                    <LogoContainer>
                        <Logo src={process.env.PUBLIC_URL + '/logo-light-theme.svg'} alt="logo" style={{ float: 'left' }} />
                        {helpers.logoText(0, { fontsize: 15, display: 'inline', marginLeft: 5, float: 'left', verticalAlign: 'super' })}
                    </LogoContainer>
                </HomeButton>
                {
                    open &&
                    <ExitWarning
                        open={open}
                        confirmHandler={confirmExit}
                        rejectHandler={rejectExit}
                    />
                }
                <div style={{ textAlign: 'right', display: 'inline-block' }}>
                    <PathogenTablet>{pathogenName}</PathogenTablet>
                    {tiparsDisabledTextTablet()}
                    {renderTextTablet('db_name', staticMeta && `${staticMeta.db_version} (${staticMeta.db_info})`, 30, true)}
                    {renderTextTablet('last_updated', staticMeta && staticMeta.last_updated, 50, true)}
                </div>
            </HeaderContainer>
            <StatsContainer>
                <div>
                    <StatsText>
                        <EqualizerRoundedIcon fontSize='small' style={{ display: 'inline', color: 'inherit', verticalAlign: 'top', marginRight: 10 }} />summary stats.
                    </StatsText>
                </div>
                <TextTabletWrapper>
                    {renderTextTablet('seq_num', activeData && helpers.numberWithCommas(activeData.seqNum), 20)}
                    {!(activeData && !('continent' in activeData.dists)) && renderTextTablet('continent_num', activeData && 'continent' in activeData.dists && String(Object.keys(activeData.dists.continent).length), 8)}
                    {!(activeData && !('country' in activeData.dists)) && renderTextTablet('country_num', activeData && 'country' in activeData.dists && String(Object.keys(activeData.dists.country).length), 10)}
                    {activeData && Object.entries(activeData.dists.lineages).map(([lineage, data], i) => (renderTextTablet(`${lineage}_num`, String(Object.keys(data).length), 20)))}
                    {!(activeData && !('host' in activeData.dists)) && renderTextTablet('host_num', activeData && 'host' in activeData.dists && String(Object.keys(activeData.dists.host).length), 8)}
                    {renderTextTablet('earliest_date', activeData && String(activeData.dists.dateCountry.earliest), 20)}
                    {renderTextTablet('latest_date', activeData && String(activeData.dists.dateCountry.latest), 20)}
                    {!(activeData && !('ageGender' in activeData.dists)) && renderTextTablet('min_age', activeData && 'ageGender' in activeData.dists && String(activeData.dists.ageGender.min), 8)}
                    {!(activeData && !('ageGender' in activeData.dists)) && renderTextTablet('max_age', activeData && 'ageGender' in activeData.dists && String(activeData.dists.ageGender.max), 8)}
                </TextTabletWrapper>
            </StatsContainer>
        </Root>
    );
};

export default Header;