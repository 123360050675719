import React from "react";
import HeaderWhite from "../landing/home/HeaderWhite";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import sarsGreen from "../../images/sars-cov2_green.png";
import sarsOrange from "../../images/sars-cov2_orange.png";
import denguePurple from '../../images/denv_purple.png';
import h5blue from '../../images/h5_blue.png';

const TryNow = () => {
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid container justifyContent="center">
          <Grid item md={8} sm={10} xs={12}>
            <div className="external-pages">
              <div className="text-container-m">
                <h1 style={{ fontSize: "5rem" }}>
                  <span style={{ color: "#ED6A5A" }}>TRY</span> NOW!
                </h1>
                <div className="text-button-container">
                  <p>
                    Our aim is to provide continually updated reference
                    phylogenies and processed epidemiological data to enable
                    real-time tracking of pathogen evolution and spread.
                    Implementation is currently only available for SARS-CoV-2.
                    Please keep an eye on{" "}
                    <a href="/recentUpdate">RECENT UPDATES</a> for our latest
                    work and upcoming developments!
                  </p>
                </div>
              </div>
              <div className="external-page-divider" style={{ marginBottom: 50 }} />
              <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={6}>
                <Link
                    to="/sars-cov-2-100k"
                    className="try-now-entry"
                    style={{
                      position: "relative",
                      backgroundImage: `url(${sarsGreen})`,
                      boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                      borderRadius: 15,
                      opacity: 0.97,
                      backgroundSize: "cover",
                      textDecoration: "none",
                      display: "block",
                      height: "250px",
                      width: '100%',
                      marginBottom: 0
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: 700,
                        position: "absolute",
                        bottom: 45,
                        marginLeft: 20,
                        opacity: 0.8,
                        color: "white",
                      }}
                    >
                      SARS-CoV-2 [100k]
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 25,
                        fontWeight: 700,
                        color: "#00801e",
                        width: "auto",
                        opacity: 0.8,
                        backgroundColor: "white",
                        padding: "5px 10px",
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                    >
                      <span style={{ color: "#484848", fontSize: 15 }}>TIPars: </span>ENABLED
                    </Typography>
                    <Typography
                      style={{
                        position: "absolute",
                        bottom: 15,
                        marginLeft: 20,
                        color: "white",
                        fontSize: 15,
                        fontWeight: 700,
                        opacity: 0.8,
                      }}
                    >
                      (LAST UPDATED: 2024-03-10)
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Link
                    to="/sars-cov-2-7m"
                    className="try-now-entry"
                    style={{
                      position: "relative",
                      backgroundImage: `url(${sarsOrange})`,
                      boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                      borderRadius: 15,
                      opacity: 0.97,
                      backgroundSize: "cover",
                      textDecoration: "none",
                      display: "block",
                      height: "250px",
                      width: '100%',
                      marginBottom: 0
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: 700,
                        position: "absolute",
                        bottom: 45,
                        marginLeft: 20,
                        opacity: 0.8,
                        color: "white",
                      }}
                    >
                      SARS-CoV-2 [7M]
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 25,
                        fontWeight: 700,
                        color: "#b00000",
                        width: "auto",
                        opacity: 0.8,
                        backgroundColor: "white",
                        padding: "5px 10px",
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                    >
                      <span style={{ color: "#484848", fontSize: 15 }}>TIPars: </span>DISABLED
                    </Typography>
                    <Typography
                      style={{
                        position: "absolute",
                        bottom: 15,
                        marginLeft: 20,
                        color: "white",
                        fontSize: 15,
                        fontWeight: 700,
                        opacity: 0.8,
                      }}
                    >
                      (LAST UPDATED: 2024-07-19)
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Link
                    to=""
                    className="try-now-entry"
                    style={{
                      position: "relative",
                      backgroundImage: `url(${denguePurple})`,
                      boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                      borderRadius: 15,
                      opacity: 0.7,
                      backgroundSize: "cover",
                      textDecoration: "none",
                      display: "block",
                      height: "250px",
                      width: '100%',
                      marginBottom: 0
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: 700,
                        position: "absolute",
                        bottom: 45,
                        marginLeft: 20,
                        opacity: 0.8,
                        color: "white",
                      }}
                    >
                      DENV [TBD]
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 25,
                        fontWeight: 700,
                        color: '#00801e',
                        width: "auto",
                        opacity: 0.8,
                        backgroundColor: "white",
                        padding: "5px 10px",
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                    >
                      <span style={{ color: "#484848", fontSize: 15 }}>TIPars: </span>ENABLED
                    </Typography>
                    <Typography
                      style={{
                        position: "absolute",
                        bottom: 15,
                        marginLeft: 20,
                        color: "white",
                        fontSize: 15,
                        fontWeight: 700,
                        opacity: 0.8,
                      }}
                    >
                      (COMING SOON)
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Link
                    to="/h5-30k"
                    className="try-now-entry"
                    style={{
                      position: "relative",
                      backgroundImage: `url(${h5blue})`,
                      boxShadow: "0 0 15px rgba(0,0,0,0.5)",
                      borderRadius: 15,
                      opacity: 0.97,
                      backgroundSize: "cover",
                      textDecoration: "none",
                      display: "block",
                      height: "250px",
                      width: '100%',
                      marginBottom: 0
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 30,
                        fontWeight: 700,
                        position: "absolute",
                        bottom: 45,
                        marginLeft: 20,
                        opacity: 0.8,
                        color: "white",
                      }}
                    >
                      H5N1 [30k]
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 25,
                        fontWeight: 700,
                        color: '#00801e',
                        width: "auto",
                        opacity: 0.8,
                        backgroundColor: "white",
                        padding: "5px 10px",
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                    >
                      <span style={{ color: "#484848", fontSize: 15 }}>TIPars: </span>ENABLED
                    </Typography>
                    <Typography
                      style={{
                        position: "absolute",
                        bottom: 15,
                        marginLeft: 20,
                        color: "white",
                        fontSize: 15,
                        fontWeight: 700,
                        opacity: 0.8,
                      }}
                    >
                      (LAST UPDATED: 2024-11-03)
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ height: 50 }} />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default TryNow;
