import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

import allActions from '../../../redux';
import KeywordTable from './keywordTable';

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: 700,
}));

const Description = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.main.normal,
    fontSize: 13,
    fontWeight: 700,
    marginBottom: 0,
}));

const ActionButton = styled(Button)(({ theme }) => ({
    minWidth: 0,
    fontSize: 14,
    fontWeight: 700,
    marginRight: 15,
    padding: '3px 12px',
    color: theme.palette.text.title,
}));

const ResetButton = styled(ActionButton)(({ theme }) => ({
    borderColor: "#fff",
    "&:hover": {
        backgroundColor: 'transparent',
    },
    padding: 10,
    paddingRight: 0,
}));

const SaveButton = styled(ActionButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.lighter,
    "&:hover": {
        opacity: 0.8,
    },
    marginBottom: 7,
    marginTop: 8,
}));

const Label = styled('span')(({ theme }) => ({
    fontSize: 13,
    display: "inline",
    textTransform: 'none',
}));

function IdNameSelect(props) {
    const dispatch = useDispatch();

    const { active, disabled, resetTrigger, inputPlaceholder, pageLimit, keywordsNumMax, buttonLabel, dialogTitle, dialogDescription } = props;
    const { ButtonComponent } = props;

    const staticMeta = useSelector(state => state.staticReducer.meta);
    const savedKeywords = useSelector(state => state.filtersReducer.keywords);

    const [open, setOpen] = React.useState(false);
    const _onOpen = () => {
        setPreSaveKeywords(savedKeywords);
        setOpen(true);
    };
    const _onClose = () => {
        setOpen(false);
    };

    const [preSaveKeywords, setPreSaveKeywords] = React.useState();
    const _onReset = () => {
        setPreSaveKeywords([]);

        dispatch(allActions.filtersActions.saveKeywords([]));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };
    const _onSave = () => {
        dispatch(allActions.filtersActions.saveKeywords(preSaveKeywords));
        dispatch(allActions.filtersActions.setApiFilteringParams());
        _onClose();
    };

    useEffect(() => {
        if (staticMeta !== null && active) {
            dispatch(allActions.filtersActions.saveKeywords([]));
            dispatch(allActions.filtersActions.setApiFilteringParams());
        }
    }, [resetTrigger]); // eslint-disable-line react-hooks/exhaustive-deps
  
    return (
        <Fragment>
            <ButtonComponent
                name={buttonLabel}
                openFunc={_onOpen}
                disabled={disabled}
                active={active}
            />
            <Dialog open={open} maxWidth={"xs"} onClose={_onClose}>
                <DialogTitle style={{ paddingBottom: 5 }}>
                    <Title>
                        {dialogTitle}
                    </Title>
                </DialogTitle>
                <DialogContent>
                    <Description>
                        {dialogDescription}
                    </Description>
                    <KeywordTable
                        keywords={preSaveKeywords}
                        handleKeywordsOnChange={setPreSaveKeywords}
                        pageLimit={pageLimit}
                        keywordsNumMax={keywordsNumMax}
                    />
                </DialogContent>
                <DialogActions>
                    <ResetButton
                        disableRipple={true}
                        onClick={_onReset}
                    >
                        <Label>
                            Reset
                        </Label>
                    </ResetButton>
                    <SaveButton
                        disableRipple={true}
                        onClick={_onSave}
                    >
                        <Label>
                            Save
                        </Label>
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default IdNameSelect;