import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import Home from "./components/landing/home";
import FAQ from "./components/landing/faq";
import RecentUpdate from "./components/landing/recentUpdate";
import TryNow from "./components/landing/TryNow";
import OurTeam from "./components/landing/ourTeam";
import Tutorial from "./components/landing/tutorial";
import Usecase1 from "./components/landing/tutorial/Usecase1";
import Usecase2 from "./components/landing/tutorial/Usecase2";
import Usecase3 from "./components/landing/tutorial/Usecase3";
import Usecase4 from "./components/landing/tutorial/Usecase4";
import TreeVisualisation from "./components/landing/tutorial/TreeVisualisation";
import TreePartitioning from "./components/landing/tutorial/TreePartitioning";
import ContactUs from "./components/landing/contact";
import MainApp from './components/main';
import theme_green from './theme_green';
import theme_orange from './theme_orange';
import theme_blue from './theme_blue';

const API_URL = process.env.NODE_ENV === "production" ? `https://${window.location.host}/api/` : process.env.REACT_APP_API_URL;

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tutorial" element={<Tutorial />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/recentUpdate" element={<RecentUpdate />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/ourTeam" element={<OurTeam />} />
      <Route path="/tryNow" element={<TryNow />} />
      <Route
        path="/sars-cov-2-100k"
        element={
          <ThemeProvider theme={theme_green}>
            <MainApp
              pathogenName="SARS-CoV-2"
              mutsDisabled={false}
              tiparsDisabled={false}
              globalDataFilteringDisabled={false}
              globalDataFilteringDisabledForced={true}
              apiURL={API_URL + "covid-100k"}
            />
          </ThemeProvider>
        }
      />
      <Route
        path="/sars-cov-2-7m"
        element={
          <ThemeProvider theme={theme_orange}>
            <MainApp
              pathogenName="SARS-CoV-2"
              mutsDisabled={true}
              tiparsDisabled={true}
              globalDataFilteringDisabled={true}
              globalDataFilteringDisabledForced={false}
              apiURL={API_URL + "covid-7m"}
            />
          </ThemeProvider>
        }
      />
      <Route path="/h5-30k" element={
        <ThemeProvider theme={theme_blue}>
          <MainApp
            pathogenName="H5"
            mutsDisabled={true}
            tiparsDisabled={true}
            globalDataFilteringDisabled={false}
            globalDataFilteringDisabledForced={false}
            apiURL={API_URL + "h5"}
          />
        </ThemeProvider>
      } />
      <Route path="/useCase1" element={<Usecase1 />} />
      <Route path="/useCase2" element={<Usecase2 />} />
      <Route path="/useCase3" element={<Usecase3 />} />
      <Route path="/useCase4" element={<Usecase4 />} />
      <Route path="/TreeVisualisation" element={<TreeVisualisation />} />
      <Route path="/TreePartitioning" element={<TreePartitioning />} />
    </Routes>
  );
}

export default App;
