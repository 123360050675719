import axios from 'axios'

import { useFilteringRequest } from '../../requestConstructor';

const FILTERED_GLOBAL_URL = '/tree/global-filtered/';
const FILTERED_GLOBAL_SUBTREES_URL = '/tree/global-subtrees-filtered/';
const SUBTREE_META_STATIC_URL = '/tree/subtree-meta-static/';
const SUBTREE_META_FILTERED_URL = '/tree/subtree-meta-filtered/';
const SUBTREE_GRAPH_STATIC_URL = '/tree/subtree-graph-static/';
const SUBTREE_GRAPH_FILTERED_URL = '/tree/subtree-graph-filtered/';
const NODE_DATA_STATIC_URL = '/tree/node-static/';
const NODE_DATA_FILTERED_URL = '/tree/node-filtered/';

// Set initial filters on initial meta load
const setInitialFilters = (staticMeta) => {
    return {
        type: 'SET_INITIAL_FILTERS',
        payload: staticMeta
    }
};

// Activate filters
const activateFilters = (isActive=null) => {
    return {
        type: 'ACTIVATE_FILTERS',
        payload: isActive
    }
};

// Collection date filtering
const saveCollectionDate = (newEarliest, newLatest, newYearUnknownExcluded, newMonthUnknownExcluded, filterActive) => {
    return {
        type: 'SAVE_COLLECTION_DATE',
        payload: {
            earliest: newEarliest,
            latest: newLatest,
            yearUnknownExcluded: newYearUnknownExcluded,
            monthUnknownExcluded: newMonthUnknownExcluded,
            filterActive: filterActive,
        }
    }
};

// Continent filtering
const saveContinents = (newContinentIds, filterActive) => {
    return {
        type: 'SAVE_CONTINENTS',
        payload: {
            continentIds: newContinentIds,
            filterActive: filterActive
        }
    }
};

// Country filtering
const saveCountries = (newCountryIds, filterActive) => {
    return {
        type: 'SAVE_COUNTRIES',
        payload: {
            countryIds: newCountryIds,
            filterActive: filterActive
        }
    }
};

// Lineage filtering
const saveLineages = (lineageChanged, newLineageIds, filterActive) => {
    return {
        type: 'SAVE_LINEAGES',
        payload: {
            lineage: lineageChanged,
            lineageIds: newLineageIds,
            filterActive: filterActive
        }
    }
};

// Host filtering
const saveHosts = (newHostIds, filterActive) => {
    return {
        type: 'SAVE_HOSTS',
        payload: {
            hostIds: newHostIds,
            filterActive: filterActive
        }
    }
};

// Age filtering
const saveAge = (newMin, newMax, newUnknownExcluded, filterActive) => {
    return {
        type: 'SAVE_AGE',
        payload: {
            min: newMin,
            max: newMax,
            unknownExcluded: newUnknownExcluded,
            filterActive: filterActive
        }
    }
};

// Gender filtering
const saveGenders = (newGenderIds, filterActive) => {
    return {
        type: 'SAVE_GENDERS',
        payload: {
            genderIds: newGenderIds,
            filterActive: filterActive
        }
    }
}

// Accession/Name filtering
const saveKeywords = (newKeywords) => {
    return {
        type: 'SAVE_KEYWORDS',
        payload: newKeywords
    }
}

// Keywords filtering
const addKeyword = (newKeyword, keywordsNumMax) => {
    return {
        type: 'ADD_KEYWORD',
        payload: {
            newKeyword: newKeyword,
            keywordsNumMax: keywordsNumMax
        }
    }
}

// Mutations filtering
const saveMuts = (newMuts) => {
    return {
        type: 'SAVE_MUTS',
        payload: newMuts
    }
}

const addMut = (newMut, mutsNumMax) => {
    return {
        type: 'ADD_MUT',
        payload: {
            newMut: newMut,
            mutsNumMax: mutsNumMax
        }
    }
}

const setMutMode = (newMutMode) => {
    return {
        type: 'SET_MUT_MODE',
        payload: newMutMode
    }
}

//--------------------------------------------------------//
// Make async API post request for filtered global data
const setApiFilteringParams = (isInitial=false) => (dispatch, getState) => {
    const { filtersReducer, staticReducer } = getState();
    const requestDataJSON = useFilteringRequest(filtersReducer, staticReducer);
    dispatch({
        type: 'SET_API_FILTERING_PARAMS',
        payload: {
            isInitial: isInitial,
            requestDataJSON: requestDataJSON
        }
    });
};

const loadFilteredGlobalData = (newCancelToken, apiURL) => async (dispatch, getState) => {
    const defaultRequestDataJSON = getState().filtersReducer.filteringParamsStore.default;
    const requestDataJSON = getState().filtersReducer.filteringParamsStore.curr;
    const getGlobalFilteredData = getState().filtersReducer.globalFilteredDataAggregation;
    const staticGlobalData = getState().staticReducer.staticGlobalData;

    if (defaultRequestDataJSON !== requestDataJSON) {
        try {
            dispatch({
                type: 'START_LOAD_FILTERED_GLOBAL_DATA',
            });
            const response = await axios.post(apiURL + (getGlobalFilteredData ? FILTERED_GLOBAL_URL : FILTERED_GLOBAL_SUBTREES_URL), requestDataJSON, {
                headers: {
                    'Content-Type': 'application/json'
                },
                cancelToken: newCancelToken()
            });
            dispatch({
                type: 'END_LOAD_FILTERED_GLOBAL_DATA',
                payload: getGlobalFilteredData ? response.data : { ...response.data, dists: { ...response.data.dists, ...staticGlobalData.dists } }
            });
        } catch(error) {
            dispatch({
                type: 'LOAD_FILTERED_GLOBAL_DATA_ERROR',
                payload: error.message
            });
        }
    } else {
        dispatch({
            type: 'END_LOAD_FILTERED_GLOBAL_DATA',
            payload: null // clear filteredGlobalData and deactivate filters
        });
    }
};

// Enable/Disable global (filtered) data aggregation
const toggleGlobalFilteredDataAggregation = (isActive=null) => {
    return {
        type: 'TOGGLE_GLOBAL_FILTERED_DATA_AGGREGATION',
        payload: isActive
    }
};

//--------------------------------------------------------//
// Make async API GET request for static subtree-specific metadata
const loadStaticSubtreeData = (subtreeId, newCancelToken, apiURL) => async (dispatch, getState) => {
    try {
        dispatch({
            type: 'START_LOAD_STATIC_SUBTREE_DATA',
        });
        const response = await axios.get(apiURL + `${SUBTREE_META_STATIC_URL}${subtreeId}`, { cancelToken: newCancelToken() });
        dispatch({
            type: 'END_LOAD_STATIC_SUBTREE_DATA',
            payload: response.data
        });
    } catch(error) {
        dispatch({
            type: 'LOAD_STATIC_SUBTREE_DATA_ERROR',
            payload: error.message
        });
    }
};

// Make async API post request for filtered subtree-specific metadata
const loadFilteredSubtreeData = (subtreeId, newCancelToken, apiURL, reload=false) => async (dispatch, getState) => {
    const defaultRequestDataJSON = getState().filtersReducer.filteringParamsStore.default;
    const apiFilteringParamsVersion = reload ? 'prev' : 'curr';
    const requestDataJSON = getState().filtersReducer.filteringParamsStore[apiFilteringParamsVersion];

    if (defaultRequestDataJSON !== requestDataJSON) {
        try {
            dispatch({
                type: 'START_LOAD_FILTERED_SUBTREE_DATA',
            });
            const response = await axios.post(apiURL + `${SUBTREE_META_FILTERED_URL}${subtreeId}/`, requestDataJSON, {
                headers: {
                    'Content-Type': 'application/json'
                },
                cancelToken: newCancelToken()
            });
            dispatch({
                type: 'END_LOAD_FILTERED_SUBTREE_DATA',
                payload: {
                    reload: reload,
                    data: response.data
                }
            });
        } catch(error) {
            dispatch({
                type: 'LOAD_FILTERED_SUBTREE_DATA_ERROR',
                payload: error.message
            });
        }
    } else {
        dispatch({
            type: 'END_LOAD_FILTERED_SUBTREE_DATA',
            payload: null // clear filteredSubtreeData and deactivate filters
        });
    }
};

// Reset subtree data on deselect
const resetStaticSubtreeData = () => {
    return {
        type: 'RESET_STATIC_SUBTREE_DATA',
    }
};

const resetFilteredSubtreeData = () => {
    return {
        type: 'RESET_FILTERED_SUBTREE_DATA',
    }
};

// Reset filteringParamsStore.prev to default or previous global filtering params on subtree deselect
const resetFilteringParamsOnSubtreeDeselect = () => {
    return {
        type: 'RESET_FILTERING_PARAMS_ON_SUBTREE_DESELECT',
    }
};

//--------------------------------------------------------//
// Make async API GET request for static subtree graph
const loadStaticSubtreeGraph = (subtreeId, newCancelToken, apiURL) => async (dispatch) => {
    try {
        dispatch({
            type: 'START_LOAD_STATIC_SUBTREE_GRAPH',
        });
        const response = await axios.get(apiURL + `${SUBTREE_GRAPH_STATIC_URL}${subtreeId}/`, { cancelToken: newCancelToken() });
        dispatch({
            type: 'END_LOAD_STATIC_SUBTREE_GRAPH',
            payload: response.data
        });
    } catch(error) {
        dispatch({
            type: 'LOAD_STATIC_SUBTREE_GRAPH_ERROR',
            payload: error.message
        });
    }
};

// Reset static subtree graph on deselect
const resetStaticSubtreeGraph = () => {
    return {
        type: 'RESET_STATIC_SUBTREE_GRAPH',
    }
};

// Make async API GET request for filtered subtree graph
const loadFilteredSubtreeGraph = (subtreeId, newCancelToken, apiURL, reload=false) => async (dispatch, getState) => {
    const defaultRequestDataJSON = getState().filtersReducer.filteringParamsStore.default;
    const apiFilteringParamsVersion = reload ? 'prev' : 'curr';
    const requestDataJSON = getState().filtersReducer.filteringParamsStore[apiFilteringParamsVersion];

    if (defaultRequestDataJSON !== requestDataJSON) {
        try {
            dispatch({
                type: 'START_LOAD_FILTERED_SUBTREE_GRAPH',
            });
            const response = await axios.post(apiURL + `${SUBTREE_GRAPH_FILTERED_URL}${subtreeId}/`, requestDataJSON, {
                headers: {
                    'Content-Type': 'application/json'
                },
                cancelToken: newCancelToken()
            });
            dispatch({
                type: 'END_LOAD_FILTERED_SUBTREE_GRAPH',
                payload: response.data
            });
        } catch(error) {
            dispatch({
                type: 'LOAD_FILTERED_SUBTREE_GRAPH_ERROR',
                payload: error.message
            });
        }
    } else {
        dispatch({
            type: 'END_LOAD_FILTERED_SUBTREE_GRAPH',
            payload: null // clear filteredSubtreeGraph and deactivate filters
        });
    }
};

// Reset filtered subtree graph on deselect
const resetFilteredSubtreeGraph = () => {
    return {
        type: 'RESET_FILTERED_SUBTREE_GRAPH',
    }
};

//--------------------------------------------------------//
// Make async API GET request for static node data
const loadStaticNodeData = (nodeId, newCancelToken, apiURL) => async (dispatch) => {
    try {
        dispatch({
            type: 'START_LOAD_STATIC_NODE_DATA'
        });
        const response = await axios.get(apiURL + `${NODE_DATA_STATIC_URL}${nodeId}/`, { cancelToken: newCancelToken() });
        dispatch({
            type: 'END_LOAD_STATIC_NODE_DATA',
            payload: response.data
        });
    } catch(error) {
        dispatch({
            type: 'LOAD_STATIC_NODE_DATA_ERROR',
            payload: error.message
        });
    }
};

// Reset static node data on deselect
const resetStaticNodeData = () => {
    return {
        type: 'RESET_STATIC_NODE_DATA',
    }
};

// Make async API post request for filtered node data
const loadFilteredNodeData = (nodeId, newCancelToken, apiURL, reload=false) => async (dispatch, getState) => {
    const defaultRequestDataJSON = getState().filtersReducer.filteringParamsStore.default;
    const apiFilteringParamsVersion = reload ? 'prev' : 'curr';
    const requestDataJSON = getState().filtersReducer.filteringParamsStore[apiFilteringParamsVersion];

    if (defaultRequestDataJSON !== requestDataJSON) {
        try {
            dispatch({
                type: 'START_LOAD_FILTERED_NODE_DATA',
            });
            const response = await axios.post(apiURL + `${NODE_DATA_FILTERED_URL}${nodeId}/`, requestDataJSON, {
                headers: {
                    'Content-Type': 'application/json'
                },
                cancelToken: newCancelToken()
            });
            dispatch({
                type: 'END_LOAD_FILTERED_NODE_DATA',
                payload: response.data
            });
        } catch(error) {
            dispatch({
                type: 'LOAD_FILTERED_NODE_DATA_ERROR',
                payload: error.message
            });
        }
    } else {
        dispatch({
            type: 'END_LOAD_FILTERED_NODE_DATA',
            payload: null // clear filteredNodeData and deactivate filters
        });
    }
};

// Reset filtered node data on deselect
const resetFilteredNodeData = () => {
    return {
        type: 'RESET_FILTERED_NODE_DATA',
    }
};

//--------------------------------------------------------//
// Reset to default (except for unsaved filtering)
const resetDefault = () => {
    return {
        type: 'RESET_DEFAULT'
    }
};

//--------------------------------------------------------//

const allActions = {
    setInitialFilters,
    activateFilters,
    saveCollectionDate,
    saveContinents,
    saveCountries,
    saveLineages,
    saveHosts,
    saveAge,
    saveGenders,
    saveKeywords,
    addKeyword,
    saveMuts,
    addMut,
    setMutMode,
    setApiFilteringParams,
    loadFilteredGlobalData,
    toggleGlobalFilteredDataAggregation,
    loadStaticSubtreeData,
    loadFilteredSubtreeData,
    resetStaticSubtreeData,
    resetFilteredSubtreeData,
    resetFilteringParamsOnSubtreeDeselect,
    loadStaticSubtreeGraph,
    loadFilteredSubtreeGraph,
    resetStaticSubtreeGraph,
    resetFilteredSubtreeGraph,
    loadStaticNodeData,
    resetStaticNodeData,
    loadFilteredNodeData,
    resetFilteredNodeData,
    resetDefault
};

export default allActions;